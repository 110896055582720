import { render, staticRenderFns } from "./QR.vue?vue&type=template&id=7dcc2c57&scoped=true"
import script from "./QR.vue?vue&type=script&lang=js"
export * from "./QR.vue?vue&type=script&lang=js"
import style0 from "./QR.vue?vue&type=style&index=0&id=7dcc2c57&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dcc2c57",
  null
  
)

export default component.exports