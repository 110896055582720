<template>
    <div class="px-3">
        <div class="point_wrap bg-main p-4">
            <div class="d-flex justify-content-between align-items-center" @click="navigateToCalculate();">
                <div class="d-flex flex-column" >
                    <small class="text-white">정산 가능 금액</small>
                    <div class="d-flex align-items-center text-white">
                    <b class="fs-4 me-1">{{ point.toLocaleString() }}</b>원
                    </div>
                </div>
                <i class="far fa-chevron-right ms-2 text-light fa-xs" v-if="arrow"></i>
            </div>
        </div>
        <RegistrationPopup ref="registration"></RegistrationPopup>
    </div>
</template>

<script>
import RegistrationPopup from '@/components/popup/Registration.vue'
export default {
    components:{
        RegistrationPopup
    },
    props:{
        point:{
            type:Number
        },
        arrow:{
            type:Boolean,
            default:true
        }
    },
    methods:{
        async navigateToCalculate() {
            const currentRoute = this.$router.currentRoute;
            if (currentRoute.path !== '/mypage/calculate') {
            await this.$router.push('/mypage/calculate');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.point_wrap{
    border-radius: 22px;
}
</style>