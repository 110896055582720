<template>
  <div id="mypage" class="bg-gray-relative-100">
    <div class="bg-body">
      <SubHeader title="MY" :link="'/home'" :setting="true"></SubHeader>
    </div>
    <div class="py-1">
      <Profile class="pt-3"></Profile>
      <div class="flex-between-center m-3 mt-0 mb-4">
        <router-link
          to="/reservation/wait"
          class="d-flex flex-column bg-body rounded-4 w-50 h-px-120 p-3 shadow-200 me-2"
        >
          <div class="fw-bold flex-grow-1 align-self-start">
            <div class="d-inline-block me-2">
              <i class="fas fa-clock text-warning"></i>
              <!-- <i class="fas fa-circle fs-px-10 d-inline-block text-warning"></i> -->
            </div>
            <span>결제대기</span>
          </div>
          <div
            class="fs-2 fw-bolder d-flex justify-content-end align-items-center"
          >
            <span
              ><span class="text-main">{{ hold_reservation }}</span
              >건</span
            >
            <i
              class="fas fa-chevron-right fs-px-14 ms-2 text-gray-relative-500"
            ></i>
          </div>
        </router-link>
        <router-link
          to="/reservation/cancel"
          class="d-flex flex-column bg-body rounded-4 w-50 h-px-120 p-3 shadow-200 me-2"
        >
          <div class="fw-bold flex-grow-1 align-self-start">
            <div class="d-inline-block me-2">
              <i class="fas fa-clock text-warning"></i>
              <!-- <i class="fas fa-circle fs-px-10 d-inline-block text-warning"></i> -->
            </div>
            <span>취소대기</span>
          </div>
          <div
            class="fs-2 fw-bolder d-flex justify-content-end align-items-center"
          >
            <span
              ><span class="text-main">{{ cancel_reservation }}</span
              >건</span
            >
            <i
              class="fas fa-chevron-right fs-px-14 ms-2 text-gray-relative-500"
            ></i>
          </div>
        </router-link>
        <router-link
          to="/reservation/confirm"
          class="d-flex flex-column bg-body rounded-4 w-50 h-px-120 p-3 shadow-200"
        >
          <div class="fw-bold flex-grow-1">
            <div class="d-inline-block me-2">
              <i class="fas fa-check-circle text-main"></i>
              <!-- <i class="fas fa-circle fs-px-10 d-inline-block text-main"></i> -->
            </div>
            <span>결제내역</span>
          </div>
          <div
            class="fs-2 fw-bolder d-flex justify-content-end align-items-center"
          >
            <span>{{ history_reservation }}건</span>
            <i
              class="fas fa-chevron-right fs-px-14 ms-2 text-gray-relative-500"
            ></i>
          </div>
        </router-link>
      </div>
      <!-- <p class="fs-px-12 px-3 text-gray-relative-500">최근 일주일간 내역이 표시됩니다.</p> -->
      <ul class="my-2 shadow-200 bg-body rounded-4 mx-3">
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center" to="/mypage/item">
            <span class="fs-px-14">상품관리</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center" to="/mypage/history">
            <span class="fs-px-14">거래내역</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center" to="/mypage/calculate">
            <span class="fs-px-14">정산내역</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="pb-3">
      <ul class="shadow-200 bg-body rounded-4 mx-3">
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center" to="/cs/notice">
            <span class="fs-px-14">공지사항</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center" to="/cs/faq">
            <span class="fs-px-14">FAQ</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center" to="/cs/inquiry/list">
            <span class="fs-px-14">문의내역</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- <div class="pb-3">
      <ul class="shadow-200 bg-body rounded-4 mx-3">
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center " to="/myinfo">
            <span class="fs-px-14">내정보</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
      </ul>
    </div> -->
    <div class="pb-3" v-if="auth == 'M'">
      <ul class="shadow-200 bg-body rounded-4 mx-3">
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center" to="/manager">
            <span class="fs-px-14">매니저관리</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center" to="/franchisee">
            <span class="fs-px-14">매장관리</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue';
import Profile from '@/components/mypage/ProfileComponent.vue';
const CryptoJS = require('crypto-js');
export default {
  components: {
    SubHeader,
    Profile,
  },
  data() {
    return {
      auth: this.$store.state.auth,
      history_reservation: '',
      hold_reservation: '',
      cancel_reservation: '',
    };
  },
  mounted() {
    this.GetReservationDashboard();
  },
  methods: {
    GetReservationDashboard() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http
        .post('/shop/reservation/GetReservationDashboard', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              const e_body = res.data.body;
              const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
              );
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res);
              this.hold_reservation = body.info.hold;
              this.history_reservation = body.info.history;
              this.cancel_reservation = body.info.cancel;
            } else if (res.data.code == '9999') {
              this.$store.dispatch('SETLOGOUT').then(() => {
                window.location.href = '/signin';
              });
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#mypage {
  min-height: calc(100vh - 70px) !important;
  padding-bottom: 20px;
}
</style>
