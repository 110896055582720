<template>
    <div id="GiftCard" class="bg-gray-relative-100">
        <div class="bg-body">
            <SubHeader :setting="true"></SubHeader>
            <GiftCard class="pb-3" :point="total_point" :arrow="false"/>
            <ul class="shop_list p-3 pt-0" v-if="my_item&&my_item.length>0">
                <li class="shop_item border border-gray-relative-200" v-for="(item,index) in my_item" :key="index">
                    <div class="ratio ratio-1x1 w-50">
                        <div class="img_thumbnail bg-gray-200 d-flex align-items-center justify-content-center">
                            <img :src="item.img" alt="" height="50">
                        </div>
                    </div>
                    <div class="w-50 p-3 d-flex flex-column justify-content-around">
                        <div class="fs-px-13 text-truncate">{{ item.name }}</div>
                        <div class="fs-px-12 text-gray-relative-500">2023-04-13</div>
                        <div class="text-center"><button class="btn border-0 rounded-2 fs-px-12 w-100 mt-2 bg-gray-relative-300 text-body" @click.prevent="qrFn(item)"><i class="fal fa-qrcode me-1"></i> QR CODE</button></div>
                    </div>
                </li>
            </ul>
            <div class="p-3 py-6 text-center text-gray-relative-500" v-else>
                <i class="fal fa-shopping-cart fa-3x text-gray-relative-400 d-block my-3"></i>
                <p class="my-2 fs-px-13">보유 중인 상품권이 없어요. <br>상점에서 상품권을 구입할 수 있어요.</p>
            </div>
        </div>
        <QrPopup ref="qrPopup" :item_info="select_item"></QrPopup>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import GiftCard from '@/components/mypage/GiftCardComponent.vue'
import QrPopup from '@/components/popup/QR.vue'
export default {
    components: {
        SubHeader, GiftCard, QrPopup
    },
    data(){
        return{
            select_item:{},
            my_item:[
                {
                    img:require('@/assets/img/gift_card/10000.png'),
                    name:'모바일 기프트카드 1만원',
                    price:10000,
                },
                {
                    img:require('@/assets/img/gift_card/30000.png'),
                    name:'모바일 기프트카드 3만원',
                    price:30000,
                },
                {
                    img:require('@/assets/img/gift_card/30000.png'),
                    name:'모바일 기프트카드 3만원',
                    price:30000,
                },
                {
                    img:require('@/assets/img/gift_card/30000.png'),
                    name:'모바일 기프트카드 3만원',
                    price:30000,
                },
            ]
        }
    },
    computed:{
        total_point(){
            let point = 0;
            this.my_item.forEach( item => {
                point += item.price;
            })
            return point;
        }
    },
    methods:{
        qrFn(item){
            this.$refs.qrPopup.pp = true;
            this.select_item= item;
        }
    }
}
</script>

<style lang="scss" scoped>
.shop_list{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    .shop_item{
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        height: 120px;
        .qr_btn{
            width: 100%;
            height: 42px;
            left: initial;
            top: initial;
            right: 0;
            bottom: 0;
        }
    }
}
</style>
