<template>
  <div id="history">
    <div>
      <SubHeader title="정산내역"></SubHeader>
    </div>
    <div class="">
      <div class="p-3 pt-0">
        <div class="rounded-2 p-3 calculate_box">
          <button class="btn border-0 btn-sm btn-main text-body-i position-absolute cal_btn"><small>대금 지급 주기</small><br>D+{{calc_day}}일</button>

          <!-- 정산신청은 자동임. 본인이 숫자입력 하는게 X -->
          <small class="fs-px-12">금일 지급 예정 금액</small>
          <h5 class="fw-bold">{{CheckNumber(total_price)}} 원</h5>
          <hr class="my5-">
          <p class="fs-px-12 mb-2 fw-bold">정산 입금 계좌</p>
          <p class="fs-px-13 mb-3">{{bank_name}} {{ CheckBankAccount(bank_account) }}<br>예금주 {{bank_holder}}</p>
          <p class="text-muted fs-px-11"><i class="far fa-exclamation-circle me-1"></i>대금 지급 주기는 영업일 기준이며 주말 및 공휴일은 포함되지 않습니다. 정산 계좌 변경을 원할 시, 관리자에 문의하세요.</p>
        </div>
      </div>
      
      <div class="px-3 mb-2">
          <ul class="d-flex gap-2 mb-2">
              <li class="w-25">
                  <span class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" @click="ChangeDate(0)" :class="{'bg-gray-relative-300':cate===0}">전체</span>
              </li>
              <li class="w-25">
                  <span class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" @click="ChangeDate(1)" :class="{'bg-gray-relative-300':cate===1}">1일</span>
              </li>
              <li class="w-25">
                  <span class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" @click="ChangeDate(2)" :class="{'bg-gray-relative-300':cate===2}">7일</span>
              </li>
              <li class="w-25">
                  <span class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" @click="ChangeDate(3)" :class="{'bg-gray-relative-300':cate===3}">30일</span>
              </li>
          </ul>
          <div class="d-flex gap-2 mb-2">
              <input class="form-control" type="date" v-model="start_date">
              <input class="form-control" type="date" v-model="end_date">
              <button class="btn border-0 btn-sm bg-secondary text-white w-px-80 flex-shrink-0" @click="SearchList()">조회</button>
          </div>
          <div class="flex-between-center px-1 mt-4">
              <span>
                <small class="text-gray-relative-600">합계</small>
                {{CheckNumber(list_total)}}
                <small class="text-gray-relative-600">원</small>
                <small class="text-gray-relative-600 fs-px-11 ms-2 me-1">/</small>
                {{list_count}} 
                <small class="text-gray-relative-600">건</small>
              </span>
              <span>
                <img src="@/assets/img/icon/excel.svg" alt="엑셀로 내보내기" width="27" @click="ExcelDownload()">
              </span>
          </div>
      </div>
      <ul class="last-border-0" v-if="list&&list.length>0">
          <li class="border-bottom border-gray-relative-200" v-for="(item,index) in list" :key="index">
              <div class="d-flex flex-column">
                  <div class="flex-between-center py-2 bg-gray-100 px-4">
                    <small class="text-muted fs-px-11 ">{{ item.date }}</small>
                    
                    <small class="text-gray-relative-500 mb-1">
                        <span class="badge bg-success" v-if="item.status==='H'">입금 예정</span>
            
                        <span class="badge bg-primary" v-else>정산 완료</span>
                        <!-- 정산 신청일 -->
            
                    </small>
                  </div>
                  <div class="flex-between-center px-4 py-3">
                      <div class="d-flex flex-column fs-px-13">
                          <span class="fs-px-11">총 정산 금액</span>
                          <span class="fs-px-12 text-end text-gray-relative-500">
                            <span>{{ CheckNumber(item.price) }}원 <span class="text-danger">- {{CheckNumber(item.fee_price)}}원</span></span>
                          </span>
                      </div>
                      <div class="d-flex flex-column">
                          <small class="text-end">+
                            {{ CheckNumber(item.calculate) }}원
                          </small>
                          <span class="fs-px-12 text-end text-gray-relative-500">
                              <span>수수료 <span class="text-danger">{{item.fee}}%</span></span>
                          </span>
                      </div>
                  </div>
              </div>
          </li>
      </ul>
      <div class="p-3 py-6 text-center text-gray-relative-500" v-else>
          <i class="fal fa-calendar-alt fa-3x text-gray-relative-400 d-block my-3"></i>
          <p class="my-2 fs-px-13">정산 내역이 없어요.</p>
      </div>
    </div>
  </div>
</template>

<script>
const CryptoJS = require("crypto-js");
var moment = require('moment');
import * as XLSX from 'xlsx'
import SubHeader from '@/components/common/SubHeader.vue'

export default {
  components: {
    SubHeader
  },
  data(){
    return{
      category:1,
      
      cate:0,
      start_date:'',
      end_date:'',
      total_price : '0',
      bank_name : '',
      bank_holder : '',
      bank_account : '',
      calc_day : '',
      list:[

      ],
      list_total : '0',
      list_count : '0'
    }
  },
  mounted(){
    this.ShopCalculateInfo();
    this.ShopCalculateList();
  },
  methods : {
    CheckBankAccount(account){
      const title = account.substring(0,8) + "****"

      return title;
    },
    SearchList(){
      this.ShopCalculateList();
    },
    ExcelDownload(){
        let excel = [];
        for (const el of this.list){
          let status ='';

          if(el.status =='Y'){
            status ="정산 완료"
          }else if(el.status =='H'){
            status ="입금 예정"
          }

          excel.push({'결제일' : el.date,'정산일' : el.calc_date,'매출' : el.price,'수수료' : el.fee_price,'정산금액' : el.calculate,'결제 상태' : status});

        }
        
        const dataWS = XLSX.utils.json_to_sheet(excel);
        const wb = XLSX.utils.book_new();
        
        XLSX.utils.book_append_sheet(wb, dataWS, `정산내역`);
        XLSX.writeFile(wb,`정산내역.xlsx`);
      },
    CheckNumber(value){
      return value.toLocaleString();
    },
    ShopCalculateInfo(){
        const body = {}
        const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
        this.$http.post('/shop/calculation/ShopCalculateInfo', {req}).then(  
            res => {
                if(res.status ==200){
                    if(res.data.code=='200'){
                        
                        const e_body = res.data.body;
                        const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                        const d_res = bytes.toString(CryptoJS.enc.Utf8);
                        const body = JSON.parse(d_res);  

                        this.calc_day = body.info.calc_day;
                        this.bank_name = body.info.bank_name;
                        this.bank_holder = body.info.bank_holder;
                        this.bank_account = body.info.bank_account;
                        this.total_price = body.info.total_price;

                    }else if(res.data.code=='9999'){
                        this.$store.dispatch('SETLOGOUT').then(
                            ()=>{
                                window.location.href="/signin";
                            }
                        )
                    }
                }
            }
        )
    },
    ChangeDate(cate){
      if(cate ==0){
        this.start_date ='';
        this.end_date = '';
        this.cate = 0;
      }else if(cate ==1){
        const end_date = moment().format("YYYY-MM-DD");
        const start_date = moment().add(-1, 'days').format("YYYY-MM-DD");
        this.start_date =start_date;
        this.end_date = end_date;
        this.cate = 1
      }else if(cate ==2){
        const end_date = moment().format("YYYY-MM-DD");
        const start_date = moment().add(-7, 'days').format("YYYY-MM-DD");
        this.start_date =start_date;
        this.end_date = end_date;
        this.cate = 2
      }else if(cate ==3){
        const end_date = moment().format("YYYY-MM-DD");
        const start_date = moment().add(-30, 'days').format("YYYY-MM-DD");
        this.start_date =start_date;
        this.end_date = end_date;
        this.cate = 3
      }
    },
    ShopCalculateList(){
      const start_date = this.start_date;
      const end_date = this.end_date;
        const body = {start_date,end_date}
        const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
        this.$http.post('/shop/calculation/ShopCalculateList', {req}).then(  
            res => {
                if(res.status ==200){
                    if(res.data.code=='200'){
                        
                        const e_body = res.data.body;
                        const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                        const d_res = bytes.toString(CryptoJS.enc.Utf8);
                        const body = JSON.parse(d_res);  

                        this.list = body.list;
                        this.list_total = body.total;
                        this.list_count = body.count;

                    }else if(res.data.code=='9999'){
                        this.$store.dispatch('SETLOGOUT').then(
                            ()=>{
                                window.location.href="/signin";
                            }
                        )
                    }
                }
            }
        )
    },
  }
}
</script>

<style lang="scss" scoped>

#history {
    min-height: calc(100vh - 70px) !important;
    padding-bottom: 20px;
}
.calculate_box{
  position: relative;
  background-color: #c3f1dc;
  .cal_btn{
    top: 15px;
    right: 15px;
  }
}
.category{
  padding: 3px;
}
</style>