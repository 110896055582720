<template>
  <div id="history" class="bg-gray-relative-100">
    <div class="pb-3">
      <SubHeader title="거래내역"></SubHeader>
    </div>
    <div class="px-3 mb-2">
      <div class="flex-between-center mb-3">
        <select
          class="form-select bg-body border-gray-relative-300 w-px-100 h-px-38 me-1 fs-px-13"
          :class="{
            'text-gray-500': search_filter == '',
            'text-body': search_filter != '',
          }"
          v-model="search_filter"
        >
          <option
            class="text-body"
            v-for="(item, index) in search_filter_list"
            :key="index"
            :value="item.code"
          >
            {{ item.name }}
          </option>
        </select>
        <input
          type="text"
          class="form-control bg-body border-gray-relative-300 h-px-38 rounded-end-0"
          v-model="search_text"
        />
        <div
          class="form-control bg-body border-gray-relative-300 w-px-38 text-center h-px-38 flex-center-center rounded-start-0 ms-px--1"
          @click="GetHistory()"
        >
          <i class="fas fa-search"></i>
        </div>
      </div>
      <ul class="d-flex gap-2 mb-2">
        <li class="w-25">
          <span
            class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3"
            @click="ChangeDate(0)"
            :class="{ 'bg-gray-relative-300': cate === 0 }"
            >전체</span
          >
        </li>
        <li class="w-25">
          <span
            class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3"
            @click="ChangeDate(1)"
            :class="{ 'bg-gray-relative-300': cate === 1 }"
            >1일</span
          >
        </li>
        <li class="w-25">
          <span
            class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3"
            @click="ChangeDate(2)"
            :class="{ 'bg-gray-relative-300': cate === 2 }"
            >7일</span
          >
        </li>
        <li class="w-25">
          <span
            class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3"
            @click="ChangeDate(3)"
            :class="{ 'bg-gray-relative-300': cate === 3 }"
            >30일</span
          >
        </li>
      </ul>
      <div class="d-flex gap-2 mb-2">
        <input class="form-control" type="date" v-model="start_date" />
        <input class="form-control" type="date" v-model="end_date" />
        <button
          class="btn border-0 btn-sm bg-secondary text-white w-px-80 flex-shrink-0"
          @click="GetHistory()"
        >
          조회
        </button>
      </div>
      <div class="flex-between-center px-1 mt-4">
        <span>
          <small class="text-gray-relative-600 me-2">합계</small
          >{{ total_price.toLocaleString() }}
          <small class="text-gray-relative-600">원</small>
          <small class="text-gray-relative-600 fs-px-11 ms-2 me-2">/</small
          >{{ total_count.toLocaleString() }}
          <small class="text-gray-relative-600">건</small>
        </span>

        <span>
          <img
            src="@/assets/img/icon/excel.svg"
            alt="엑셀로 내보내기"
            width="27"
            @click="ExcelDownload()"
          />
        </span>
      </div>
    </div>
    <div class="mx-3 bg-body rounded-4 shadow-400">
      <div class="my-2 pb-2">
        <ul class="mx-3 last-border-0" v-if="list && list.length > 0">
          <li
            class="px-2 py-3 flex-between-center border-bottom border-gray-relative-200"
            v-for="(item, index) in list"
            :key="index"
            @click="$router.push(`/payment/receipt/${item.code}`)"
          >
            <div class="flex-between-center">
              <div class="d-flex flex-column fs-px-13">
                <small class="text-gray-relative-500"
                  >{{ item.date
                  }}<span
                    class="ms-2 badge bg-success"
                    v-if="item.status === 'Y'"
                    >{{ CheckStatus(item.status) }}</span
                  ><span
                    class="ms-2 badge bg-danger"
                    v-if="item.status === 'C'"
                    >{{ CheckStatus(item.status) }}</span
                  ></small
                >
                <p class="lh-1 fw-bold">{{ item.payment_name }}</p>
              </div>
            </div>
            <!-- <div class="flex-between-center"> -->
            <div class="d-flex flex-column price_tag">
              <!--  border-end pe-3 -->
              <small class="text-end text-danger"
                >{{ item.price.toLocaleString() }}원</small
              >
              <span class="fs-px-12 text-end text-gray-relative-500"
                ><span>{{ item.user_name }} 고객</span></span
              >
            </div>
            <!-- <span class="badge w-px-30 h-px-30 rounded-3 fw-normal fs-px-12 px-2 ms-3 d-flex flex-column align-items-center justify-content-center shadow-100 border" @click="$router.push(`/payment/receipt/${item.code}`)">
                            <i class="fal fa-receipt text-muted"></i>
                        </span>
                    </div> -->
          </li>
        </ul>
        <div class="p-3 py-6 text-center text-gray-relative-500" v-else>
          <i
            class="fal fa-shopping-cart fa-3x text-gray-relative-400 d-block my-3"
          ></i>
          <p class="my-2 fs-px-13">
            거래 내역이 없어요.<br />상점에서 상품권을 구입하고 선물할 수
            있어요.
          </p>
        </div>
      </div>
    </div>

    <Pagination
      :length="total_count"
      @click="(n) => [handlePaging(n), GetHistory()]"
      v-if="total_count != 0"
    />
    <Loading v-if="loading" />
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue';
import Profile from '@/components/mypage/ProfileComponent.vue';
import Loading from '@/components/common/Loading.vue';
import Pagination from '@/components/common/Pagination.vue';
const CryptoJS = require('crypto-js');
var moment = require('moment');
import * as XLSX from 'xlsx';

export default {
  components: {
    SubHeader,
    Profile,
    Loading,
    Pagination,
  },
  data() {
    return {
      total_price: 0,
      total_count: 0,
      start_date: '',
      end_date: '',
      search_filter: '',
      search_text: '',
      cate: 0,
      search_filter_list: [
        {
          code: '',
          name: '전체',
        },
        {
          code: 'N',
          name: '고객이름',
        },
        {
          code: 'I',
          name: '고객 아이디',
        },
        {
          code: 'C',
          name: '상품권 핀번호',
        },
      ],
      list: [],
      loading: false,
      page: 1,
      limit: 10,
    };
  },
  computed: {},
  mounted() {
    this.GetHistory();
  },
  methods: {
    handlePaging(n) {
      this.page = n;
    },
    ChangeDate(cate) {
      if (cate == 0) {
        this.start_date = '';
        this.end_date = '';
        this.cate = 0;
      } else if (cate == 1) {
        const end_date = moment().format('YYYY-MM-DD');
        const start_date = moment().add(-1, 'days').format('YYYY-MM-DD');
        this.start_date = start_date;
        this.end_date = end_date;
        this.cate = 1;
      } else if (cate == 2) {
        const end_date = moment().format('YYYY-MM-DD');
        const start_date = moment().add(-7, 'days').format('YYYY-MM-DD');
        this.start_date = start_date;
        this.end_date = end_date;
        this.cate = 2;
      } else if (cate == 3) {
        const end_date = moment().format('YYYY-MM-DD');
        const start_date = moment().add(-30, 'days').format('YYYY-MM-DD');
        this.start_date = start_date;
        this.end_date = end_date;
        this.cate = 3;
      }
    },
    ExcelDownload() {
      const start_date = this.start_date;
      const end_date = this.end_date;
      const filter = this.search_filter;
      const text = this.search_text;
      const body = { start_date, end_date, filter, text };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();

      this.loading = true;
      this.$http.post('/shop/history/GetHistory', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);

            let excel = [];
            for (const el of body.list) {
              let status = '';

              if (el.status == 'Y') {
                status = '승인';
              } else if (el.status == 'C') {
                status = '취소';
              }

              excel.push({
                날짜: el.date,
                결제방법: el.payment_name,
                고객이름: el.user_name,
                금액: el.price,
                '결제 상태': status,
              });
            }

            const dataWS = XLSX.utils.json_to_sheet(excel);
            const wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, dataWS, `거래내역`);
            XLSX.writeFile(wb, `거래내역.xlsx`);
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              window.location.href = '/signin';
            });
          }
        }
      });
    },
    CheckStatus(status) {
      if (status == 'Y') {
        return '승인';
      } else if (status == 'C') {
        return '취소';
      }
    },
    GetHistory() {
      const start_date = this.start_date;
      const end_date = this.end_date;
      const filter = this.search_filter;
      const text = this.search_text;
      const page = this.page;
      const limit = this.limit;
      const body = { start_date, end_date, filter, text, page, limit };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http.post('/shop/history/GetHistory', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);
            this.list = body.list;
            this.total_price = body.info.price;
            this.total_count = body.info.count;
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              window.location.href = '/signin';
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#history {
  min-height: calc(100vh - 70px) !important;
  padding-bottom: 20px;
}
.category {
  padding: 3px;
}
.buy_type {
  background: linear-gradient(to bottom, #5d9cec, #4a89dc);
}
.send_type {
  background: linear-gradient(to bottom, #3f51b5, #243e87);
}
.text-send {
  color: #3f51b5;
}
.check_type {
  background: linear-gradient(to bottom, #a0d468, #8cc152);
}
.price_tag {
  min-width: 30%;
}
</style>
