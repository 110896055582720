<template>
    <div class="basic_pp top_down" v-show="pp" :class="{'off':off}">
         <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
         <div class="content bg-body text-center">
            <div class="flex-between-center mb-2 ps-3">
                
                <b class="fs-px-14">상품권 등록</b>
                <button class="btn border-0" @click="pp_off()"><i class="fal fa-times ms-auto text-gray-relative-500"></i></button>
            </div>
            <!-- <div class="text-end mb-2">
                <i class="fal fa-times ms-auto text-gray-relative-500 p-2" @click="pp_off()"></i>
            </div>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1024px-QR_code_for_mobile_English_Wikipedia.svg.png" alt="" height="150">
            <p class="text-truncate py-2 w-80 mx-auto">{{}}</p>
             -->
            <div class="p-3 pt-0 text-start">
                <p class="text-gray-relative-500 fs-px-12 mb-3">지류상품권의 핀번호 또는 QR코드를 인식하여 주십시오. 지류 상품권을 모바일 상품권으로 변환합니다.</p>
                <div class="form-group mb-2">
                    <div class="d-flex ">
                        <input class="form-control bg-body border-gray-relative-300" type="text" placeholder="핀번호">
                        <div class="btn btn-secondary flex-shrink-0 small d-flex align-items-center ms-1"><span><i class="fal fa-qrcode"></i></span></div>
                    </div>
                </div>
                <button class="btn btn-main btn-sm w-100">등록</button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return{
               pp: false,
    		off: false,
               timeout_id: null,
            }
        },
       props:{
       },
       methods:{
           // front ui
           pp_off(){
               this.off = true;
    
               if (this.timeout_id) {
                   clearTimeout(this.timeout_id);
               }
    
               this.timeout_id = setTimeout(() => {
                   this.pp = false;
                   this.off = false;
                    if( this.$refs.basic_pp_body ){
                        this.$refs.basic_pp_body.scrollTop = 0;}
                   this.timeout_id = null;
               }, 200);
           }
       }
    
    }
</script>
<style lang="scss" scoped>
    .bank_logo{
    width: 150px;
    margin: 0 auto;
    object-fit: cover;
    margin-bottom: 10px;
    }
    .basic_pp .content{
    max-height: 90vh;
    }
</style>