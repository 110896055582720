<template>
    <div class="bg-gray-relative-100">
        <SubHeader title="내 찜 목록"></SubHeader>

		<div>
			<SearchList :list="list" />
		</div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import SearchList from '@/components/search/SearchList.vue'

export default {
    components:{
        SubHeader, SearchList
    },
    data(){
        return{
			list: [
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1610916445778-09401a50a2b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
					title: '스카이골프 CC',
					address: '대전 광역시 대전로 213',
					contact: '000-000-0000',
					site: 'www.somesite.com',
					tags: ['some','tag'],
					bookmark: true,
					reserve: true,
					country: 'kr',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1610916445778-09401a50a2b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
					title: '스카이골프 CC',
					address: '대전 광역시 대전로 213',
					contact: '',
					site: 'www.naver.com',
					tags: ['태그','미정'],
					bookmark: true,
					reserve: true,
					country: 'kr',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1610916445778-09401a50a2b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
					title: '스카이골프 CC',
					address: '대전 광역시 대전로 213',
					contact: '000-000-0000',
					site: 'www.naver.com',
					tags: [],
					bookmark: true,
					reserve: true,
					country: 'kr',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1610916445778-09401a50a2b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
					title: '스카이골프 CC',
					address: '대전 광역시 대전로 213 대전 광역시 대전로 213 대전 광역시 대전로 213',
					contact: '000-000-0000',
					site: 'www.naver.com',
					tags: [],
					bookmark: true,
					reserve: true,
					country: 'kr',
				},
			],
			
		}
    }
}
</script>