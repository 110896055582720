<template>
  <div
    class="profile p-3 pt-0"
    v-if="$store.state.login"
    @click="ClickProfile()"
  >
    <div class="d-flex gap-3 align-items-center">
      <!-- <img :src="info.avatar" alt="avatar" class="avatar rounded-circle"> -->
      <div>
        <h6>{{ shop_name }}</h6>
        <small class="text-gray-relative-500">{{ name }}</small>
      </div>
    </div>
    <i class="far fa-chevron-right fa-xs"></i>
  </div>
  <div class="profile p-3 pt-0" v-else @click="$router.push('/')">
    <div class="d-flex gap-3 align-items-center">
      <img
        src="@/assets/img/profile_icon.png"
        alt="avatar"
        class="avatar rounded-circle"
      />
      <small>로그인하고 다양한 혜택을 누려보세요!</small>
    </div>
    <i class="far fa-chevron-right fa-xs"></i>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shop_name: this.$store.state.shop_name,
      name: this.$store.state.name,
      auth: this.$store.state.auth,
    };
  },
  mounted() {},
  methods: {
    ClickProfile() {
      if (this.auth == 'M') {
        this.$router.push('/mypage/profile');
      } else {
        this.$router.push('/myinfo');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
</style>
