<template>
    <div class="vh-100 bg-gray-relative-100">
        <SubHeader :title="title" class="bg-body" />
        <div class="py-2">
            <ul class="shadow-200 bg-body">
                <li class="p-3 d-flex align-items-center justify-content-between border-bottom border-gray-relative-100">
                    <span class="fs-px-14">자동로그인</span>
                    <div>
                        <div class="form-check form-switch p-0">
                            <input class="form-check-input bg-gray-relative-300 py-2" type="checkbox" role="switch" v-model="input_0" @change="handleAutoLogin">
                        </div>
                    </div>
                </li>
                <li class="p-3 d-flex align-items-center justify-content-between border-bottom border-gray-relative-100">
                    <span class="fs-px-14">푸시알림</span>
                    <div>
                        <div class="form-check form-switch p-0">
                            <input class="form-check-input bg-gray-relative-300 py-2" type="checkbox" role="switch" v-model="input_1">
                        </div>
                    </div>
                </li>
                <li class="p-3 d-flex align-items-center justify-content-between border-bottom border-gray-relative-100">
                    <span class="fs-px-14">이벤트/공지사항 알림</span>
                    <div>
                        <div class="form-check form-switch p-0">
                            <input class="form-check-input bg-gray-relative-300 py-2" type="checkbox" role="switch" v-model="input_4">
                        </div>
                    </div>
                </li>
                <!-- <li class="p-3 d-flex align-items-center justify-content-between border-bottom border-gray-relative-100">
                    <span class="fs-px-14">다크모드</span>
                    <div>
                        <div class="form-check form-switch p-0">
                            <input class="form-check-input bg-gray-relative-300 py-2" type="checkbox" role="switch" v-model="theme" @change="ChgTheme()">
                        </div>
                    </div>
                </li> -->
            </ul>
        </div>

        <div class="py-2">
            <ul class="shadow-200 bg-body">
                <li @click="show_terms=!show_terms" class="p-3 d-flex align-items-center justify-content-between border-bottom border-gray-relative-100">
                    <span class="fs-px-14">약관 및 정책</span>
                    <i class="far fa-xs text-gray-500" :class="{'fa-chevron-down':!show_terms, 'fa-chevron-up':show_terms}"></i>
                </li>
                <div v-if="show_terms">
                    <li v-for="(item,index) in terms_list" :key="index" @click="$refs.TermsPopup.pp_on(index)">
                        <div class="d-flex ps-2 justify-content-between align-items-center border-bottom border-gray-relative-100 py-1">
                            <div class="fs-px-13 text-gray-relative-500"> <span class="text-gray-relative-500 me-2">└</span> {{item.title}}</div>
                            <button class="btn btn-transparent fs-px-2"><i class="far fa-chevron-right fa-xs text-gray-500"></i></button>
                        </div>
                    </li>
                </div>
            </ul>
        </div>
        <TermsPopup ref="TermsPopup" />
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import TermsPopup from '@/components/popup/Terms.vue'
import { mapState, mapMutations } from 'vuex';

export default {
    components:{
        SubHeader, TermsPopup
    },
    data(){
        return{
            title:'설정',

            input_0: true,
            input_1: true,
            input_2: true,
            input_3: false,
            input_4: false,

            theme: false,

            terms_list: [
                {
                    idx: 0,
                    title: "운영 정책",
                },
                {
                    idx: 1,
                    title: "이용자 약관 정보",
                },
                {
                    idx: 2,
                    title: "개인정보 처리 방침",
                },
                {
                    idx: 3,
                    title: "E-mail 및 SMS 광고성 정보",
                },
            ],

            // front_ui
            show_terms: false,
        }
    },
    computed: {
        ...mapState(['is_auto_login']),
    },
    methods: {
        ...mapMutations(['SetAutoLogin']),
        ChgTheme(){
            if(this.$store.state.theme == 'light'){
            this.$store.commit('ChgTheme', 'dark');
            }else{
            this.$store.commit('ChgTheme', 'light');
            }
        },
        GetCheckboxStore() {
            this.input_0 = this.is_auto_login;
        },
        handleAutoLogin() {
            this.SetAutoLogin(this.input_0);
        },
    },
    mounted() {
        this.GetCheckboxStore();
        this.theme = this.$store.state.theme=='dark' ? true : false;
    }
}
</script>

<style>

</style>