<template>
    <div id="profile" class="bg-gray-relative-100">
        <div class="bg-body">
            <SubHeader title="프로필" :setting="true"></SubHeader>
        </div>
        <div class="avatar">
            <div class="w-100 position-relative" >
                <!-- <span class="position-absolute badge bg-body end-0 bottom-0 shadow h-px-30 w-px-30 translate-middle" style="line-height:22px;" @click="chooseFiles()">
                    <i class="fas fa-camera text-dark" ></i>
                    <input id="fileUpload" type="file" hidden @change="UploadFile" accept="image/*" capture="camera">
                </span> -->
                <ul class="mypage_cate bg-body d-flex py-3 shadow">
                    <li @click="category=1">내정보</li>
                    <li @click="category=2" class="border-start">사업자</li>
                    <li @click="category=3" class="border-start">가맹점</li>
                    <li @click="category=4" class="border-start">비밀번호변경</li>
                    <!-- <li @click="category=3">담당자</li> -->
                </ul>
            </div>
        </div>
        <div class="p-3 pt-4">
            <div class="bg-body rounded-4 shadow-300 p-3" v-show="category===1">
                <h6 class="py-3 px-2 mb-3 border-bottom">내 정보</h6>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">이름</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick bg-body  text-body border-gray-relative-200" v-model="my_name" disabled>
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">부서명</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick bg-body text-body border-gray-relative-200" v-model="my_department" disabled>
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">직위</label>
                <div class="input-group mb-3">
                    <input type="text"  class="form-control thick bg-body text-body border-gray-relative-200" v-model="my_rank" disabled>
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">전화번호</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick bg-body text-body border-gray-relative-200" v-model="my_tel" disabled>
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">긴급연락처</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick bg-body text-body border-gray-relative-200" v-model="my_e_tel" disabled>
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">팩스번호</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick bg-body text-body border-gray-relative-200" v-model="my_fax" disabled>
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">이메일</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick bg-body  text-body border-gray-relative-200" v-model="my_email" disabled>
                </div>
            </div>
            
            <div class="bg-body rounded-4 shadow-300 p-3" v-show="category===2">
                <h6 class="py-3 px-2 mb-3 border-bottom">사업자 정보</h6>
                <!-- <p class="fs-px-12 text-gray-relative-500 mb-3 px-2">사업자 정보 변경을 원하시면 관리자에 문의하세요.</p> -->
                <label for="name" class="form-label fs-px-12 mb-1 px-2 fw-bold">사업 형태</label>
                <input disabled type="text" id="title" class="form-control thick text-body border-gray-relative-200 mb-3" v-model="shop_type">

                <!-- <div class="radio_btn_box">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="radio1_1" v-model="info.type" value="1" disabled>
                        <label class="form-control text-body border-gray-relative-300" for="radio1_1">개인사업자</label>
                    </div>
                    <div class="form-check ms-1">
                        <input class="form-check-input" type="radio" id="radio1_2" v-model="info.type" value=2 disabled>
                        <label class="form-control text-body border-gray-relative-300" for="radio1_2">법인</label>
                    </div>
                </div> -->
                <label for="title" class="form-label fs-px-12 mb-1 px-2 fw-bold">법인명</label>
                <div class="input-group mb-3">
                    <input disabled type="text" id="title" class="form-control thick text-body border-gray-relative-200" v-model="business_name">
                </div>
                <label for="business_number" class="form-label fs-px-12 mb-1 px-2 fw-bold">사업자 등록번호</label>
                <div class="input-group mb-3">
                    <input disabled type="tel" id="business_number" class="form-control thick text-body border-gray-relative-200" v-model="business_number">
                </div>
                <label for="corporation_number" class="form-label fs-px-12 mb-1 px-2 fw-bold">법인 등록번호</label>
                <div class="input-group mb-3">
                    <input disabled type="tel" id="corporation_number" class="form-control thick text-body border-gray-relative-200" v-model="corporate_number">
                </div>
                <label for="name" class="form-label fs-px-12 mb-1 px-2 fw-bold">대표자명</label>
                <div class="input-group mb-3">
                    <input disabled type="text" id="name" class="form-control thick text-body border-gray-relative-200" v-model="owner">
                </div>
                <label for="name" class="form-label fs-px-12 mb-1 px-2 fw-bold">전화번호</label>
                <div class="input-group mb-3">
                    <input disabled type="text" id="name" class="form-control thick text-body border-gray-relative-200" v-model="tel">
                </div>
                <div class="d-flex">
                    <div class="me-2">
                        <label for="name" class="form-label fs-px-12 mb-1 px-2 fw-bold">업태</label>
                        <div class="input-group mb-3">
                            <input disabled type="text" id="name" class="form-control thick text-body border-gray-relative-200" v-model="business_type">
                        </div>
                    </div>
                    
                    <div>
                        <label for="name" class="form-label fs-px-12 mb-1 px-2 fw-bold">종목</label>
                        <div class="input-group mb-3">
                            <input disabled type="text" id="name" class="form-control thick text-body border-gray-relative-200" v-model="business_item">
                        </div>
                    </div>
                </div>
                <!-- <label for="address" class="form-label fs-px-12 mb-1 px-2 fw-bold">주소</label>
                <div class="input-group mb-3">
                    <input disabled type="text" id="address" class="form-control thick text-body border-gray-relative-200" v-model="address">
                </div>
                
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">사이트 주소 URL</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick text-body border-gray-relative-200"  v-model="site_url">
                </div> -->
            </div>

            <div class="bg-body rounded-4 shadow-300 p-3" v-show="category===3">
                <h6 class="py-3 px-2 mb-3 border-bottom">가맹점 정보</h6>

                <p class="fs-px-12 text-gray-relative-500 mb-3 px-2">대금 지급 주기 및 정산 계좌 정보 변경을 원하시면 관리자에 문의해주세요.</p>

                <!-- <label for="address" class="form-label fs-px-12 mb-1 px-2 fw-bold">주소</label>
                <div class="input-group mb-1">
                    <input type="text" id="address" class="form-control thick bg-body text-body border-gray-relative-200" v-model="info.address">
                    <span class="input-group-text fs-px-12" id="basic-addon2 bg-success">주소 찾기</span>
                </div>
                <div class="input-group mb-1">
                    <input type="text" id="address" class="form-control thick bg-body text-body border-gray-relative-200" placeholder="주소">
                </div>
                <div class="input-group mb-1">
                    <input type="text" id="address" class="form-control thick bg-body text-body border-gray-relative-200" placeholder="상세 주소">
                </div> -->

                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">가맹점 수수료</label>
                <div class="input-group mb-3">
                    <input disabled type="text" class="form-control thick text-body border-gray-relative-200" v-model="fee">
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">대금 지급 주기</label>
                <div class="input-group mb-3">
                    <input disabled type="text" class="form-control thick text-body border-gray-relative-200" v-model="cycle">
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">은행명</label>
                <div class="input-group mb-3">
                    <input disabled type="text" class="form-control thick text-body border-gray-relative-200" v-model="bank_name">
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">계좌번호</label>
                <div class="input-group mb-3">
                    <input disabled type="tel" class="form-control thick text-body border-gray-relative-200" v-model="bank_account">
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">예금주</label>
                <div class="input-group mb-3">
                    <input disabled type="text" class="form-control thick text-body border-gray-relative-200" v-model="bank_holder">
                </div>
            </div>

            
            
            <div class="bg-body rounded-4 shadow-300 p-3" v-show="category===4">
                <label for="password" class="form-label fs-px-12 mb-1 px-2 fw-bold">비밀번호 변경</label>
                <div class="input-group mb-1">
                    <input type="password" id="password" class="form-control thick text-body border-gray-relative-200" placeholder="현재 비밀번호" v-model="old_password" autocomplete="none">
                </div>
                <div class="input-group mb-1">
                    <input type="password" id="new_password" class="form-control thick text-body border-gray-relative-200" placeholder="신규 비밀번호" v-model="new_password" autocomplete="none">
                </div>
                <div class="input-group mb-1">
                    <input type="password" id="new_password2" class="form-control thick text-body border-gray-relative-200" placeholder="신규 비밀번호 확인" v-model="new_password_confirm" autocomplete="none">
                </div>
                <button class="btn thick bg-secondary text-white mt-2 w-100" @click="ChangePassword()">변경</button>
            </div>
            
            <!-- <div class="bg-body rounded-4 shadow-300 p-3" v-show="category===3">
                <h6 class="py-3 px-2 mb-3 border-bottom">담당자 정보</h6>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">담당자 이름</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick text-body border-gray-relative-200" v-model="manager_name">
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">담당자 부서명</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick text-body border-gray-relative-200" v-model="manager_department">
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">담당자 직위</label>
                <div class="input-group mb-3">
                    <input type="text"  class="form-control thick bg-body text-body border-gray-relative-200" v-model="manager_rank">
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">담당자 전화번호</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick text-body border-gray-relative-200" v-model="manager_tel">
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">담당자 긴급연락처</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick text-body border-gray-relative-200" v-model="manager_e_tel">
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">담당자 팩스번호</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick text-body border-gray-relative-200" v-model="manager_fax">
                </div>
                <label class="form-label fs-px-12 mb-1 px-2 fw-bold">담당자 이메일</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control thick text-body border-gray-relative-200" v-model="manager_email">
                </div>
            </div>
             -->
            <!-- 
            <form>
                <label for="password" class="form-label fs-px-12 mb-1 px-2 fw-bold">비밀번호 변경</label>
                <div class="input-group mb-1">
                    <input type="password" id="password" class="form-control thick text-body border-gray-relative-200" placeholder="현재 비밀번호" v-model="current_pw" autocomplete="none">
                </div>
                <div class="input-group mb-1">
                    <input type="password" id="new_password" class="form-control thick text-body border-gray-relative-200" placeholder="신규 비밀번호" v-model="new_pw" autocomplete="none">
                </div>
                <div class="input-group mb-1">
                    <input type="password" id="new_password2" class="form-control thick text-body border-gray-relative-200" placeholder="신규 비밀번호 확인" v-model="new_pw2" autocomplete="none">
                </div>
                <button class="btn thick bg-secondary text-white mt-2 w-100">변경</button>
            </form> -->

        </div>
        <div class="px-4 py-3 text-gray-relative-500 fs-px-13 text-end">
            <span @click="LogOut()">로그아웃</span>
            <!-- <span class="text-gray-relative-300 mx-3 text-opacity-50">|</span>
            <router-link to="/opt-out">회원탈퇴</router-link> -->
        </div>
    </div>
</template>
<script>
const CryptoJS = require("crypto-js");
import AWS from 'aws-sdk';
import SubHeader from '@/components/common/SubHeader.vue'
export default {
    components: {
        SubHeader
    },
    data() {
        return {
            category:1,
            shop_image : '',
            current_pw: '',
            new_pw: '',
            new_pw2: '',
            Bucket : process.env.VUE_APP_AWSBUCKETNAME,
            region : process.env.VUE_APP_AWSBUCKETREGION,
            IdentityPoolId: process.env.VUE_APP_AWSPOOLID,
            AWSURL: process.env.VUE_APP_AWSURL,
            //샵정보
            business_name : '',
            business_item : '',
            business_number : '',
            business_type : '',
            code : '',
            corporate_number : '',
            image_path  : '',
            shop_name : '',
            owner : '',
            shop_type : '',
            site_url : '',
            tel : '',
            fee:'',
            cycle : '',
            bank_name : '',
            bank_account : '',
            bank_holder:'',
            my_name : '',
            my_department : '',
            my_rank : '',
            my_tel : '',
            my_e_tel :'',
            my_fax : '',
            my_email : '',
            old_password :'',
            new_password:'',
            new_password_confirm:'',
        }
    },
    mounted() {
        // 임시 데이터
        this.GetMyInfo()
        this.GetShopInfo();
    },
    methods: {
        ChangePassword(){
            const old_password  = this.old_password;
            const new_password = this.new_password;
            const new_password_confirm = this.new_password_confirm;

            if(old_password ==''){
                
                this.$alert("현재 비밀번호를 입력해주세요.");
                return false;
            }

            if(new_password ==''){
                this.$alert("신규 비밀번호를 입력해주세요.");
                return false;
            }

            if(new_password !=new_password_confirm){
                this.$alert("변경할 비밀번호를 확인해주세요.");
                return false;
            }


            const body = {old_password,new_password}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/shop/myinfo/ChangePassword', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){

                            this.$alert("비밀번호를 변경하였습니다.<br>확인을 위해서 재접속해주세요.").then(
                                ()=>{
                                    this.LogOut();
                                }
                            )
                            
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )
        },
        GetMyInfo(){
            const body = {}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/shop/myinfo/GetMyInfo', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  

                            const info = body.info;
                            
                            this.my_name = info.name;
                            this.my_department = info.department;
                            this.my_rank = info.rank;
                            this.my_tel = info.tel;
                            this.my_e_tel = info.e_tel;
                            this.my_fax = info.fax;
                            this.my_email = info.email;
                            
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )
        },
        UploadFile(e){
            const files = e.target.files;
            const file = files[0];
            const date = new Date();
            const timestamp = date.getTime();
            const fileName = timestamp+file.name;
            
            AWS.config.update({region: this.region,credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: this.IdentityPoolId})});
            var s3 = new AWS.S3({apiVersion: "2006-03-01",params: { Bucket: this.Bucket}});
            s3.upload({Key: fileName, Body : file, ACL:'public-read','ContentType':`${file.type}`}, (err)=>{
                if(err == null){
                    const path = this.AWSURL + fileName;
                    this.shop_image = path;
                    this.ChangeImage();
                }
            })
        },
        GetShopInfo(){
            const body = {}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/shop/myinfo/GetShopInfo', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  

                            const info = body.info;
                            const bank = body.bank;

                            this.shop_type = info.shop_type;
                            this.business_item = info.business_item;
                            this.business_name = info.business_name;
                            this.business_number = info.business_number;
                            this.business_type = info.business_type;
                            this.code = info.code;
                            this.corporate_number = info.corporate_number;
                            this.owner = info.owner;
                            this.shop_type = info.shop_type;
                            this.site_url = info.site_url;
                            this.tel = info.tel;
                            
                            this.fee = bank.fee;
                            this.cycle = bank.cycle_text;
                            this.bank_name = bank.bank_name;
                            this.bank_account = bank.bank_account;
                            this.bank_holder = bank.bank_holder;

                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )
        },
        ChangeImage(){
            
            const shop_image = this.shop_image;
            const body = {shop_image}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/shop/myinfo/ChangeImage', {req})

        },
        chooseFiles(){
            document.getElementById("fileUpload").click()
        },
        LogOut() {
            this.$store.dispatch('SETLOGOUT').then(
                ()=>{
                    window.location.href="/signin";
                }
            )
        }
    }
}
</script>
<style lang="scss" scoped>
    .avatar>div {
        width: 100%;
        height: 50px;
        background-size: cover;
    }
    .mypage_cate{
        width: 100%;
        max-width: 300px;
        text-align: center;
        font-size: 13px;
        border-radius: 10px;
        position:absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -30px;
        li{
            width: 25%;
        }
    }
</style>